import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { strategyInfoGet } from "@/apis/coin";
import { StrategyInfo } from "@/apis/model/coin";
import { FoctorBase } from "@/types";
import { getFieldObj, uniqueByKey } from "@/utils/tool";
import { getRandomColor } from "@/utils/color";
import { generateRandomId } from "@/utils";

interface StrategyState {
  chartSvgInfo: string;
  cacheKeyMap: Record<string, string>;
  newStrategyInfo?: {
    inputMode?: number;
    strategyConfig?: Record<string, any>;
    strategyId?: string;
  }; // store for new strategy
  strategyInfo?: {
    // store for edit and history strategy
    inputMode?: number;
    sid?: number;
    strategyName?: string;
    strategyConfig?: Record<string, any>;
    priceDataQueryParam?: FoctorBase[];
    strategyId?: string;
  };
  strategyEdit?: boolean;
  ohlcFactor: FoctorBase[]; // 存储open，high，low，close，为Kline做factor拆分
  chartData: ChartDataCache; // 存储图表数据
  strategyId?: string;
  clearStrategyLogic?: boolean;
}

export const fetchStrategyInfo = createAsyncThunk(
  "strategy/fetchStrategyInfo",
  async (params: StrategyInfo, thunkApi) => {
    try {
      const response = await strategyInfoGet(params);
      return response.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState: StrategyState = {
  chartSvgInfo: "",
  cacheKeyMap: {},
  ohlcFactor: [],
  chartData: {},
};
const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    setChartSvgInfo(state, action) {
      state.chartSvgInfo = action.payload;
    },
    setCacheKey(state, action) {
      const { key, value } = action.payload;
      state.cacheKeyMap[key] = value;
    },
    setOhlcFactor(state, action) {
      state.ohlcFactor = action.payload;
    },
    setChartData(state, action) {
      state.chartData = { ...state.chartData, ...action.payload };
    },
    deleteChartData(state, action) {
      const { payload } = action;
      for (let key in state.chartData) {
        if (key.startsWith(payload)) {
          delete state.chartData[key];
        }
      }
    },
    // for edit strategy and history record strategy
    setStrategyInfo(state, action) {
      const { strategyConfig } = action.payload;
      state.strategyInfo = {
        ...action.payload,
        strategyConfig: {
          ...strategyConfig,
          tradesFrees: strategyConfig.tradesFrees * 100,
          entriesLogicItems: getFieldObj(strategyConfig?.entriesLogicItems),
          exitsLogicItems: getFieldObj(strategyConfig?.exitsLogicItems),
        },
      };
    },
    // for new strategy
    setNewStrategyInfo(state, action) {
      state.newStrategyInfo = action.payload;
    },
    resetStrategyEntriesAndExits(state, action) {
      if (state.strategyInfo?.strategyConfig) {
        if (action.payload === "entry") {
          state.strategyInfo.strategyConfig.entriesLogicItems = [];
        } else if (action.payload === "exit") {
          state.strategyInfo.strategyConfig.exitsLogicItems = [];
        } else {
          state.strategyInfo.strategyConfig.entriesLogicItems = [];
          state.strategyInfo.strategyConfig.exitsLogicItems = [];
        }
      }

      state.clearStrategyLogic = true;
    },
    setClearStrategyLogic(state, action) {
      state.clearStrategyLogic = action.payload;
    },
    resetStrategy() {
      return initialState; // 返回初始状态
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchStrategyInfo.fulfilled, (state, action) => {
      state.strategyEdit = true;
      const { strategyConfig, priceDataQueryParam } = action.payload;
      const newPriceDataQueryParam = uniqueByKey(
        priceDataQueryParam,
        "factor_id"
      );
      state.strategyInfo = {
        ...action.payload,
        priceDataQueryParam: newPriceDataQueryParam.map((item: any) => {
          const factor = item.factor_id !== 5 ? item.factor : "kline";

          return {
            foctor_id: item.factor_id,
            symbol: item.symbol,
            foctor_sourse: item.factor_source,
            interval: item.interval,
            label: factor,
            foctor: factor,
            indFlag: true,
            color: getRandomColor(),
            foctor_indicator: item.factor_indicator?.map((ind: any) => ({
              // ...ind,
              symbol: item.symbol,
              foctor: item.factor_id !== 5 ? ind.factor : "kline",
              color: getRandomColor(),
              foctor_id: ind.factor_id,
              foctor_label: ind.factor,
              foctor_sourse: ind.factor_source,
              label: ind.label,
              interval: ind.interval,
              indicatorType: ind.indicatorType,
              payload: ind.payload,
              vreturn: ind.vreturn,
              chart_area: ind.chart_area,
              chart_type: ind.chart_type,
              indId: ind.indId,
              id: ind.indId + "#" + generateRandomId(),
              useScene: ind.useScene,
            })),
          };
        }),
        strategyConfig: {
          ...strategyConfig,
          tradesFrees: strategyConfig.tradesFrees * 100,
          entriesLogicItems: getFieldObj(strategyConfig?.entriesLogicItems),
          exitsLogicItems: getFieldObj(strategyConfig?.exitsLogicItems),
        },
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setChartSvgInfo,
  setCacheKey,
  setOhlcFactor,
  setChartData,
  deleteChartData,
  setStrategyInfo,
  setNewStrategyInfo,
  resetStrategyEntriesAndExits,
  setClearStrategyLogic,
  resetStrategy,
} = strategySlice.actions;

export default strategySlice.reducer;
