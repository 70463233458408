import { BasicComponentProps } from "@/types/index";
import clsx from "clsx";
import { memo } from "react";

interface InfoTypographyProps extends BasicComponentProps {
  color?: string;
  title: string;
  content: string;
}
const InfoTypography: React.FC<InfoTypographyProps> = (props) => {
  return (
    <div
      className={clsx(
        "mb-[10px] max-md:w-full  text-left slide-element",
        props.className
      )}
      key={props.title}
    >
      <div
        className={clsx(
          'md:text-2xl text-xl pl-4 mb-3 md:leading-8 font-semibold relative before:content-[""] before:absolute before:left-0 before:w-[2px] before:h-full',
          props.color
        )}
      >
        {props.title}
      </div>
      <p className='md:text-base text-sm text-[#AAAAAA] whitespace-pre-line'>
        {props.content}
      </p>
    </div>
  );
};
export default memo(InfoTypography);
