import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css"; // Make sure to create and style this CSS file accordingly
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { getUser } from "@/store/selectors";
import { useAppDispatch } from "@/hooks";
import { clearUserInfo, loadUserInfo } from "@/store/slices/user";
import Skeleton from "@mui/material/Skeleton";

const Header: React.FC = () => {
  const userState = useSelector(getUser);

  const dispatch = useAppDispatch();
  // const jwtToken = localStorage.getItem("token");
  // if (jwtToken != null) {
  //   console.log(jwtToken);
  // }

  // const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(userState);

    console.log(userState.accessToken);

    if (userState.accessToken) {
      dispatch(loadUserInfo());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const logout = () => {
    dispatch(clearUserInfo());
    window.location.href = "/";
    return;
  };

  return (
    <header className="header">
      <div className="logo" style={{ marginLeft: 10, display: "flex" }}>
        {/* <NavLink end> */}
        <img src="/logo.svg" alt="corr.ai logo" />
        {/* </NavLink> */}
        <div
          style={{
            color: "#777777",
            fontSize: 15,
            marginLeft: 5,
            marginTop: "8px",
          }}
        >
          beta
        </div>
      </div>

      <nav className="nav">
        <ul className="nav">
          {/* <li>
            <NavLink
              to="/edgest"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Edgest
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to="/strategy-markets"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Markets
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/dashboards"
              className={({ isActive }) => (isActive ? "active-link" : "")}              
            >
              My strategies
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/bt-studio"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Backtest Studio
            </NavLink>
          </li>

          {userState.userInfo ? (
            <div
              className="hover_effect_logout"
              style={{
                textDecoration: "none",
                fontSize: 13,
                position: "relative", // To position the dropdown relative to this div
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#6B6CF7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {"userState.userInfo.email".slice(0, 1).toUpperCase()}
                </span>
                <FontAwesomeIcon
                  style={{ marginLeft: 10 }}
                  icon={faChevronDown}
                />
              </div>

              <div
                className={`hover_logo ${
                  isHovered ? "hover_logo_expanded" : ""
                }`}
              >
                <div className="hover_logo_content">
                  <div
                    onClick={() => {
                      logout();
                    }}
                  >
                    Log out
                  </div>
                </div>
              </div>
            </div>
          ) : loading ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              width="46px"
              height="25px"
              sx={{ bgcolor: "grey.900", margin: 1, opacity: 0.5 }}
            />
          ) : (
            <div className="hover_effect">
              <NavLink
                to="/login"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                <span style={{ color: "#fff" }}>Login</span>
              </NavLink>
            </div>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
