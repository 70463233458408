export function intervalcount(v: string): number {
  const units: { [key: string]: number } = {
    m: 60 * 1000,
    h: 60 * 60 * 1000,
    d: 24 * 60 * 60 * 1000,
    w: 7 * 24 * 60 * 60 * 1000,
  };
  let unit: string = v.slice(-1);
  let value: number = parseInt(v.slice(0, -1), 10);
  return value * units[unit];
}

export const getFieldObj = (foctor: any) => {
  return foctor?.map((f: any) => {
    switch (f.type) {
      case "foctor":
      case "non_price_factor":
        return {
          id: f.factorId,
          dataId: f.dataId,
          foctor: f.foctor,
          foctor_sourse: f.foctorSourse,
          interval: f.interval,
          symbol: f.coinId,
          symbolLabel: f.symbol,
          type: "foctor",
          uniqueId: f.uniqueId,
        };
      case "ind":
      case "kline_ind":
      case "non_price_ind":
        return {
          dataId: f.dataId,
          foctor: f.foctor,
          id: f.factorId,
          foctor_id: f.factorId,
          foctor_ind: f.foctorInd,
          foctor_sourse: f.foctorSourse,
          ind_payload: f.indPayload,
          interval: f.interval,
          sub_payload: f.subPayload,
          sub_payload_value: f.subPayloadValue,
          symbol: f.coinId,
          symbolLabel: f.symbol,
          type: "ind",
          uniqueId: f.uniqueId,
          indId: f.indId,
          useScene: f.useScene,
        };
      case "logic_symbol":
      case "compute_symbol":
        return {
          MathSymbols: f.mathSymbols,
          id: `${f.type}_${f.mathSymbols}`,
          type: f.type,
          uniqueId: f.uniqueId,
        };
      case "contect_logic":
        return {
          ContectLogic: f.contectLogic,
          id: `${f.type}_${f.contectLogic}`,
          type: f.type,
          uniqueId: f.uniqueId,
        };
      case "number_input":
        return {
          type: f.type,
          uniqueId: f.uniqueId,
          value: f.value,
        };
      default:
        return {};
    }
  });
};

export const getFoctorObj = (field: any) => {
  return getFieldObj(field).filter(
    (f: any) => f.type === "foctor" || f.type === "ind"
  );
};

export const getFoctorIndicator = (foctor: any) => {};

export function transformData(arr1: any) {
  const result: any = [];

  arr1.forEach((item: any) => {
    const existing = result.find(
      (r: any) => r.foctor_id === item.factorId && r.symbol === item.coinId
    );

    if (!existing) {
      // 如果没有相同的 foctor_id 和 symbol 的对象，则创建一个新的
      const newItem = {
        foctor_id: item.factorId,
        symbol: item.coinId,
        foctor_sourse: item.foctorSourse,
        interval: item.interval,
        label: item.foctor,
        foctor: item.foctor,
        // color: item.type === "foctor" ? "#49FC67" : "#C755FC", // 根据 type 判断颜色
        indFlag: item.type === "foctor", // 如果是 'ind' 类型，则设置 indFlag 为 true
        foctor_indicator:
          item.type === "ind"
            ? [
                {
                  symbol: item.coinId,
                  foctor: item.foctor,
                  foctor_label: item.foctor,
                  foctor_id: item.factorId,
                  foctor_sourse: item.foctorSourse,
                  id: `${item.foctorInd}#${generateUniqueId()}`, // 生成一个唯一 ID
                  label: item.foctorInd,
                  interval: item.interval,
                  payload: item.indPayload || {},
                  vreturn: [item.foctorInd.toLowerCase()],
                  chart_area: "overlays",
                  chart_type: "line",
                  color: item.type === "ind" ? "#58F9D6" : "#FAF083", // 不同的指示器颜色
                },
              ]
            : [],
      };
      result.push(newItem);
    } else {
      // 如果已有相同的项（foctor_id 和 symbol 匹配），则需要将指示器添加到 foctor_indicator 中
      if (item.type === "ind") {
        existing.foctor_indicator.push({
          symbol: item.coinId,
          foctor: item.foctor,
          foctor_label: item.foctor,
          foctor_id: item.factorId,
          foctor_sourse: item.foctorSourse,
          id: `${item.foctorInd}#${generateUniqueId()}`, // 生成一个唯一 ID
          label: item.foctorInd,
          interval: item.interval,
          payload: item.indPayload || {},
          vreturn: [item.foctorInd.toLowerCase()],
          chart_area: "overlays",
          chart_type: "line",
          color: "#FAF083", // 默认指示器颜色
        });
      }
    }
  });

  return result;
}

// 生成唯一 ID
function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9); // 简单生成一个唯一的 ID
}

export const transformKlineData = (arr: KlineDataProps[]) => {
  return arr.map((k: KlineDataProps) => [
    k.openTime,
    k.open,
    k.high,
    k.low,
    k.close,
  ]);
};

export const ohlcArr = ["open", "high", "low", "close"];

//
export function uniqueByKey(array: Record<string, unknown>[], key: string) {
  const map = new Map();
  return array?.filter((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], true);
      return true;
    }
    return false;
  });
}
