// 我们通过以下方法来降低重复颜色的概率并提高科技感与鲜艳度：
// 1. 使用高饱和度与中高亮度，以在黑色背景下产生更好的对比度和科技风格的氛围。
// 2. 在生成色相时增加随机偏移，但限制色相值集中在较明亮、鲜艳的范围（如常见的霓虹色范围：0~360都可随机）。
// 3. 为减少重复颜色概率，我们记录上次生成的颜色，在下一次生成时如果过于接近则重新生成，直到差异足够大为止。

let lastHue: number | null = null;

export function getRandomColor(): string {
  // 定义一个函数来检查两种色相的差异是否足够大，避免与上次过于相似
  const isHueDifferentEnough = (h1: number | null, h2: number, threshold = 30): boolean => {
    if (h1 === null) return true; // 第一次生成不进行比较
    const diff = Math.abs(h1 - h2);
    return diff > threshold && diff < 360 - threshold;
  };

  let hue: number, saturation: number, lightness: number;
  let attempts = 0;

  do {
    // hue 全范围随机：0-360
    hue = Math.floor(Math.random() * 360);
    // 增大饱和度和亮度的范围以获得更鲜艳的霓虹科技感
    // saturation：90% ~ 100%，以确保颜色足够浓艳
    saturation = 90 + Math.random() * 10;
    // lightness：50% ~ 60%，在深色背景下具备很好的对比度
    lightness = 50 + Math.random() * 10;
    attempts++;
    // 避免极端情况下不断重复
    if (attempts > 10) break;
  } while (!isHueDifferentEnough(lastHue, hue));

  lastHue = hue;

  // 将HSL转换为RGB
  const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
    s /= 100;
    l /= 100;
    const k = (n: number): number => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number): number =>
      l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

    return [
      Math.round(f(0) * 255),
      Math.round(f(8) * 255),
      Math.round(f(4) * 255),
    ];
  };

  const [r, g, b] = hslToRgb(hue, saturation, lightness);

  // 转换RGB为HEX
  const rgbToHex = (r: number, g: number, b: number): string =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`;

  return rgbToHex(r, g, b);
}

export const getCandlestickColor = () => {
  return {
    color: "rgb(226, 84, 97)",
    upColor: "rgb(94, 186, 137)", 
    upLineColor: "rgb(94, 186, 137)",
    downColor: "rgb(226, 84, 97)",
  };
};
