import { CoinItem } from "@/pages/BtStudio/components/ChooseBar/ChooseBar";
import request from ".";
import { IListResponseData, IResponseData, PageParams } from "./model/request";
import { StrategyItem, StrategyPairItem } from "./model/coin";
export interface IListInterval {
  indId: number;
  intervals: string;
}
/** 币类型 */
export function coinPage(params: PageParams) {
  return request.get<IListResponseData<CoinItem>, IListResponseData<CoinItem>>(
    "/app-api/metadata/coin/page",
    { params }
  );
}
/**币时间筛选条件 */
export function listIntervals() {
  return request.get<
    IResponseData<IListInterval[]>,
    IResponseData<IListInterval[]>
  >("/app-api/metadata/coin/listIntervals");
}
/**price data */
export function listFoctors(params: { coinId: string; interval: string }) {
  return request.get("/app-api/metadata/coin/listFoctors", { params });
}
/**Indicators */
export function listInds(params: { foctorId: string; isPrice: boolean }) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/metadata/coin/listInds",
    { params }
  );
}
/** get price */
export function symbolPrice(params: {
  startTime: string;
  endTime: string;
  interval: string;
  coinId: string;
  colName: string;
}) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getPrice",
    { params }
  );
}
/** get noprice data  */
export function symbolNoPrice(params: {
  startTime: string;
  endTime: string;
  interval: string;
  foctorId: string;
}) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getNonPriceData",
    { params }
  );
}
/** get  */
export function symbolInd(params: {
  startTime: string;
  endTime: string;
  interval: string;
  coinId: string;
  colName: string;
  indName: string;
  payload: string;
  indicatorType: string;
  cacheKey: string;
}) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getInd",
    { params }
  );
}

export function getNonPriceDataInd(params: {
  startTime: string;
  endTime: string;
  interval: string;
  coinId: string;
  colName: string;
  indName: string;
  payload: string;
  cacheKey: string;
  foctorId: string;
  indicatorType: string;
  coinSymbol: string;
}) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getNonPriceDataInd",
    { params }
  );
}

export function createBackTest(data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/coin/strategy/run",
    data
  );
}
export function saveStrategy(data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/metadata/strategy/save",
    data
  );
}
export function uplodaFile(path: string, data: any) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/metadata/oss/upload",
    data,
    {
      params: {
        path,
      },
    }
  );
}
export function strategyList(params: any) {
  return request.get<any, IListResponseData<StrategyItem>>(
    "/app-api/metadata/strategy/page",
    { params }
  );
}
export function strategyListPair(params: PageParams) {
  return request.get<any, IListResponseData<StrategyPairItem>>(
    "/app-api/metadata/strategy/listPair",
    {
      params,
    }
  );
}
// 获取策略详情
export function strategyInfoGet(params: any) {
  return request.get("/app-api/metadata/strategy/get", { params });
}
/** get kline data  */
export function fetchKlineData(params: KlineProps) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getKline",
    { params }
  );
}
/** get kline indicators */
export function fetchKlineIndicators() {
  return request.get<IResponseData, IResponseData>(
    "/app-api/metadata/coin/listKlineInds"
  );
}
/** get kline indicators data */
export function fetchKlineIndicatorsData(params: KlineIndicatorProps) {
  return request.get<IResponseData, IResponseData>(
    "/app-api/coin/kline/getKlineInd",
    { params }
  );
}
/** get history backtest record */
export function fetchHistoryRecord() {
  return request.get<IResponseData, IResponseData>(
    "/app-api/metadata/strategy/createHistory"
  );
}
// 大模型接口
export function fetchChatGPT(params: ChatGPT) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/coin/chat/qa",
    params
  );
}
