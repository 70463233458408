import { memo } from "react";
import AiAgentGif from "@/static/images/aiagent.gif";
const AiAgent: React.FC = () => {
  return (
    <div className="grid grid-cols-1 border border-t-0 border-[#333333] rounded-[0_0_12px_12px] md:grid-cols-[2fr_1fr] w-full text-left md:h-[580px] items-center">
      <div className="w-full h-full flex items-center">
        <img className="w-full h-auto max-md:mb-5" alt="" src={AiAgentGif} />
      </div>
      <div className="md:px-8 sm:p-6 p-4 border-[#333333] md:border-l h-full flex flex-col justify-center slide-element">
        <div className='text-xl md:text-2xl pl-4 mb-3 leading-8 font-semibold relative before:content-[""] before:bg-[#19FB9B] before:absolute before:left-0 before:w-[2px] before:h-full'>
          AI Agent
        </div>
        <p className="text-sm md:text-base  text-[#AAAAAA]">
          Strategy-writing AI Agents - continuously mine data and provide
          strategy suggestions to help you optimize trading decisions.
        </p>
        <br />
        <p className="text-sm md:text-base  text-[#AAAAAA]">
          exchange strategies AI Agents - Offer smart agent services that use
          zero-knowledge proof to match and exchange strategies.
        </p>
      </div>
    </div>
  );
};
export default memo(AiAgent);
