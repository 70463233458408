import { memo, useState } from "react";
import LinearGradientTitle from "../components/linear-gradient-title";
import FastEfficiency from "./fast-efficiency";
import StrategyBuilder from "./strategy-builder";
import Skeleton from "@mui/material/Skeleton";

const TradingInspirations: React.FC = () => {
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleVideoReady = () => {
    setIsVideoReady(true);
  };
  return (
    <section className="md:mt-44 mt-24">
      <LinearGradientTitle className="slide-element font-normal">
        Focus on Your Trading
      </LinearGradientTitle>
      <LinearGradientTitle className="md:mb-8 mb-4 slide-element  font-normal">
        Inspirations
      </LinearGradientTitle>
      <div className="md:text-5xl text-2xl md:mb-20 mb-8 slide-element font-normal">
        Leave the rest to CorrAI.
      </div>

      <div
        style={{
          background:
            "linear-gradient(120.29deg, rgba(0, 0, 0, 0.13) 50.08%, rgba(58, 58, 58, 0.13) 76.08%)",
          paddingInline: 10,
          paddingTop: 10,
        }}
        className="border border-[#333333] rounded-[12px_12px_0_0] w-full "
      >
        {/* <img src='' className='w-full h-[579px]' alt='' /> */}
        {!isVideoReady && (
          <div className="video_loader">
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height="100%"
              sx={{ bgcolor: "grey.900", opacity: 0.5 }}
            />
          </div>
        )}
        <video
          src="https://corrai-landingpage-1257905400.cos.ap-hongkong.myqcloud.com/demo.mp4"
          muted
          loop
          playsInline
          autoPlay
          className="h-auto mr-5 max-md:!max-w-full"
          style={{ borderRadius: "10px 10px 0px 0px" }}
          onCanPlay={handleVideoReady}
        ></video>
      </div>

      <FastEfficiency />
      {/* <StrategyBuilder /> */}
    </section>
  );
};
export default memo(TradingInspirations);
