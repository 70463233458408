import React, { useEffect, useState } from "react";
import "./Login.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setToken, setUserInfo } from "../../store/slices/user";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { RoundedLoadingButton } from "@/components/basic";
import styled from "@emotion/styled";
import SignUp from "./SignUp";
import ForgetPassword from "./ForgetPassword";
import { EmailLoginParam } from "@/apis/model/user";
import { useNavigate } from "react-router-dom";
import { emailLogin } from "@/apis/user";
import { getUser } from "@/store/selectors";
import toast from "react-hot-toast";

// import WalletLogin from "./components/WalletLogin";
// import { metaMask } from "./utils/metaMask";
export const UnderlineLink = styled("span")({
  textDecoration: "underline",
  cursor: "pointer",
  margin: "0 4px",
  display: "inline-block",
});
export type pageType = "login" | "signup" | "forget";
export interface LoginComponentProps {
  onTypeChange?(type: pageType): void;
}
export const LoginBox: React.FC<{
  children: React.ReactNode;
  title: string;
}> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "400px",
          height: "500px",

          p: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img width={120} src="/logo.svg" alt="" style={{ marginBottom: 20 }} />
        <Typography variant="h5" sx={{ pb: 2 }}>
          {props.title}
        </Typography>
        {props.children}
      </Box>
    </Box>
  );
};
const Login: React.FC<LoginComponentProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [walletLoginVisible, setWalletLoginVisible] = useState(false);
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const [form, setForm] = useState<EmailLoginParam>({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });
  useEffect(() => {
    if (user.accessToken) {
      navigate("/bt-studio");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispath = useAppDispatch();
  const handleSubmit = async () => {
    if (loading) {
      return false;
    }
    const _error = { ...error };
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      _error.email = true;
    }
    if (!form.password) {
      _error.password = true;
    }
    setError(_error);
    if (Object.values(_error).some(Boolean)) {
      return false;
    }
    setLoading(true);
    emailLogin(form)
      .then((res) => {
        navigate("/bt-studio");
        dispath(
          setUserInfo({
            ...res.data,
            email: form.email,
          })
        );
        dispath(
          setToken({
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          })
        );
      })
      .catch((err) => {
        err.msg && toast.error(err.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const handleWalletLogin = () => {
  //   // metaMask?.deactivate();
  //   // metaMask
  //   //   .activate()
  //   //   .then((result) => {
  //   //     console.log(result);
  //   //   })
  //   //   .catch((err) => {});
  //   setWalletLoginVisible(true);
  // };
  const clearError = () => {
    setError({
      email: false,
      password: false,
    });
  };
  // const handleSuccessModalClose = () => {
  //   setShowSuccessModal(false);
  //   window.location.href = "/bt-studio";
  // };

  // const handleErrorModalClose = () => {
  //   setShowErrorModal(false);
  // };

  return (
    <>
      <LoginBox title="Log in">
        <Box component="form">
          <TextField
            error={error.email}
            helperText={error.email ? "Invalid Email" : ""}
            onChange={(event) => {
              setForm({
                ...form,
                email: event.target.value,
              });
              clearError();
            }}
            sx={{ mb: 2 }}
            size="small"
            required
            fullWidth
            label="Email"
          ></TextField>
          <FormControl
            sx={{ mb: 2, width: "100%" }}
            size="small"
            required
            error={error.password}
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              onChange={(event) => {
                setForm({
                  ...form,
                  password: event.target.value,
                });
                clearError();
              }}
              fullWidth
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {error.password && (
              <FormHelperText error>
                Password must be at least 6 characters
              </FormHelperText>
            )}
          </FormControl>
          {/* <Typography
            component={"span"}
            sx={{
              color: "",
              ":hover": {},
              mb: 2,
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            align="center"
            onClick={() => props.onTypeChange && props.onTypeChange("forget")}
          >
            Forgot password?
          </Typography> */}
          <RoundedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            sx={{ mt: 2, textTransform: "none" }}
          >
            Continue
          </RoundedLoadingButton>

          {/* <RoundedLoadingButton
          onClick={handleWalletLogin}
          fullWidth
          variant='outlined'
          sx={{ mt: 2, textTransform: "none" }}
        >
          Wallet Login
        </RoundedLoadingButton> */}

          <Typography
            sx={{
              color: "",
              ":hover": {},
              mt: 2,
            }}
            align="center"
          >
            Don't have an account yet?
            <UnderlineLink
              onClick={() => props.onTypeChange && props.onTypeChange("signup")}
            >
              Sign Up
            </UnderlineLink>
          </Typography>
        </Box>

        {/* <WalletLogin
        onClose={() => setWalletLoginVisible(false)}
        open={walletLoginVisible}
      ></WalletLogin> */}
      </LoginBox>
    </>
  );
};
const LoginPage = () => {
  const [type, setType] = useState<pageType>("login");
  const onTypeChange = (type: pageType) => {
    setType(type);
  };
  const loginComponent = (type: pageType) => {
    switch (type) {
      case "login":
        return <Login onTypeChange={onTypeChange} />;
      case "signup":
        return <SignUp onTypeChange={onTypeChange} />;
      default:
        return <ForgetPassword onTypeChange={onTypeChange} />;
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loginComponent(type)}
      </div>
    </>
  );
};
export default LoginPage;
